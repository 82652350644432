<template>
    <f7-sheet
        :opened="shown"
        class="grocery-product-popup"
        backdrop
        swipe-to-close
        swipe-handler='.grocery-product-popup__picture'
        close-by-backdrop-click
        close-by-outside-click
        @sheet:closed="closeSheet()"
    >
        <div class="custom-sheet-product__close-button" @click="closeSheet()"></div>
        <div v-if="loading" class="preloader-wrapper preloader-wrapper__full-height">
            <f7-preloader size="26" />
        </div>
        <div v-show="!loading">
            <div class="grocery-product-popup__container">
                <div class="grocery-product-popup__picture__wrapper">
                    <div class="grocery-product-popup__picture" 
                        :style="imageSrc(product.main_picture) ? `background-image: url(${ imageSrc(product.main_picture) }); background-size: contain;` : ''">
                        <!-- <div :class="`grocery-product-popup__discount gradient-${ BUSINESS_TYPE.GROCERY }`" v-if="product.discount">
                            {{ product.discount }}%
                        </div> -->
                    </div>
                </div>

                <div class="grocery-product-popup__content">
                    <div>
                        <div class="grocery-product-popup__title">
                            <span v-html="productTitle"></span>
                            <div>
                                <div class="grocery-product-popup__price">
                                    {{ priceFormat(product.price) }}&nbsp;Р{{ priceUnitTitle }}
                                    <div v-if="product.old_price" class="grocery-product-popup__old-price">
                                        {{ priceFormat(product.old_price) }}&nbsp;Р{{ priceUnitTitle }}
                                        <div class="grocery-product-popup__old-price__line"></div>
                                    </div>
                                    <span v-if="product.weight" class="grocery-product-popup__weight">{{ productWeight }}</span>
                                </div>
                            </div>
                        </div>

                        <hr v-if="productDescription" class="hr-line" />

                        <div class="grocery-product-popup__description">
                            <span v-if="productDescription" class="grocery-product-popup__description__item">
                                <ShowHideText :text="productDescription"/>
                            </span>
                        </div>

                        <hr v-if="productСomposition" class="hr-line" />

                        <div class="grocery-product-popup__description">
                            <span v-if="productСomposition" class="grocery-product-popup__description__item">
                                <ShowHideText :text="`<b>Состав</b>: ${ productСomposition }`"/>
                            </span>
                        </div>
                        
                        <hr v-if="productParams" class="hr-line" />

                        <div v-if="productParams" class="grocery-product-popup__description__numbers" v-html="productParams"></div>
                    </div>
                </div>
            </div>
            
            <div class="grocery-product-popup__add-to-cart">
                <div v-if="product.id" class="stepper-wrapper">
                    <div :class="`stepper ${ product.weight_unit ? 'stepper-unit' : '' }`">
                        <div @click="setItemQuantity(false)" class="stepper-button-minus"></div>
                        <div class="stepper-input-wrap stepper-value" v-html="cartQuantityFormat"></div>
                        <div @click="setItemQuantity(true)" class="stepper-button-plus"></div>
                    </div>
                </div>
                <button
                    v-if="product.id"
                    :class="`custom-button grocery-product-popup__add-to-cart__button gradient-${ BUSINESS_TYPE.GROCERY }`"
                    @click="addToCart">
                    <span>Добавить</span>
                    <span>{{ priceFormat(+product.price * quantity) }}&nbsp;Р</span>
                </button>
            </div>
        </div>
    </f7-sheet>
</template>
<script>
    import { mapState } from 'vuex';
    import store from '@/store';
    import { BUSINESS_TYPE, ORDER_TYPE, UNIT_CODE_TITLE } from '@/js/utils/vars';
    import { addItemToCart, sendAppMetricaEvent, priceFormat } from '@/js/utils/service-helpers';

    import AppPage from '@/components/common/AppPage';
    import EmptyImg from '@/components/common/EmptyImg';
    import ShowHideText from '@/components/common/ShowHideText';

    export default {
        components: {
            AppPage,
            EmptyImg,
            ShowHideText
        },
        props: {
            show: {
                type: Boolean,
                default: false
            },
            entityCode: {
                type: String,
                default: ''
            },
            productId: {
                type: Number,
                default: 0
            }
        },
        data: () => ({
            UNIT_CODE_TITLE,
            priceFormat,
            BUSINESS_TYPE,
            ORDER_TYPE,
            quantity: 1,
            stopQuantity: false,
            loading: false,
            shown: false
        }),
        computed: {
            ...mapState('grocers', ['entity', 'product']),
            ...mapState('cart', ['items', 'orderType', 'total_weight']),
            ...mapState('partners', {
                partnersList: state => state.list
            }),
            productTitle() {
                return this.product.title ? this.product.title.trim() : '';
            },
            productWeight() {
                return +this.product.weight >= 1000 ? (this.product.weight / 1000) + ' кг' : this.product.weight + ' г';
            },
            productDescription() {
                return this.product.description ? this.product.description.replace(/\n/g, '<br>').trim() : '';
            },
            productСomposition() {
                return this.product.composition ? '<b>Состав</b>: ' + this.product.composition.replace(/\n/g, '<br>').trim() : '';
            },
            productParams() {
                return this.product.params ? this.product.params.map(param => {
                    return `<div class="grocery-product-popup__description__numbers__item">${ param.name?.trim() }<br><span>${ String(param.value).trim() }</span></div>`;
                }).join('') : '';
            },
            cartQuantityFormat() {
                return `${ this.quantity } <div class="stepper-value-unit">&nbsp;${ UNIT_CODE_TITLE[+this.product.weight_unit] }</div>`;
            },
            priceUnitTitle() {
                if(+this.product.weight_unit) {
                    return `/${ UNIT_CODE_TITLE[+this.product.weight_unit] }`;
                }
                return '';
            },
        },
        watch: {
            productId: {
                deep: true,
                async handler() {
                    if(this.productId) {
                        this.loadProduct();
                        this.shown = true;
                    }
                }
            },
            show: {
                deep: true,
                async handler() {
                    if(this.productId && !this.loading) {
                        this.loadProduct();
                        this.shown = this.show;

                        if(this.show) {
                            sendAppMetricaEvent('PRODUCT_OPENED', {
                                Service: 'grocery',
                                ServiceCode: this.entityCode,
                                ProductId: this.productId,
                                InSheet: true
                            });
                        }
                    }
                }
            }
        },
        async mounted() {
            if(this.productId) {
                this.loadProduct();
                this.shown = true;
            }
        },
        methods: {
            async loadProduct() {
                this.loading = true;
                if(!this.entity || !this.entity.id || this.entity.code !== this.entityCode) {
                    await store.dispatch('grocers/getByCode', {
                        grocerCode: this.entityCode,
                    });
                }
                await store.dispatch('grocers/getProduct', {
                    grocerId: this.entity.id,
                    productId: this.productId
                });

                this.quantity = this.product.weight_step || 1;
                this.loading = false;

                sendAppMetricaEvent('PRODUCT_OPENED', {
                    Service: 'grocery',
                    ServiceCode: this.entityCode,
                    ProductId: this.productId,
                    InSheet: true
                });
            },
            closeSheet() {
                this.shown = false;
                this.$emit('close');
                window.dispatchEvent(new Event('resize'));
            },
            imageSrc(picture) {
                return picture ? `${process.env.VUE_APP_IMAGES_URL}/sq/${ picture }` : '';
            },
            setItemQuantity(increment) {
                const minValue = this.product.weight_step || 1;
                if(increment) {
                    this.quantity += this.product.weight_step || 1;
                } else if (this.quantity > minValue) {
                    this.quantity -= this.product.weight_step || 1;
                }
                this.quantity = Math.round(this.quantity * 1000) / 1000;
                return;
            },
            addToCart: function () {
                this.stopQuantity = true;
                let paymentType = 2;
                if (this.product.grocer && this.product.grocer.partner) {
                    paymentType = this.product.grocer.partner.payment;
                }

                let cartItem = {
                    entity_type_code: 'grocer',
                    entity_code: this.entity?.code,
                    categories_page: !!this.entity?.categories_page,
                    grocer_id: this.product.grocer_id,
                    entity_title: this.entity.title,
                    goods_id: this.product.id,
                    id: this.product.id,
                    picture: this.product.main_picture,
                    title: this.product.title,
                    price: this.product.price,
                    old_price: this.product.old_price,
                    item_price: this.product.price,
                    old_item_price: this.product.old_price,
                    quantity: this.quantity,
                    weight: this.product.weight || 0,
                    weight_step: this.product.weight_step || 0,
                    weight_unit: this.product.weight_unit || 0,
                    weight_limit: this.entity.weight_limit,
                    weight_limit_description: this.entity.weight_limit_description
                };
            
                addItemToCart({ 
                    orderType: ORDER_TYPE.GROCER_DELIVERY, 
                    paymentType, 
                    cartItem
                }, async () => {
                    await store.dispatch('cart/recalculateDeliveryPrice');
                    this.closeSheet();
                });
                this.stopQuantity = false;
            }
        },
    };
</script>
<style lang="scss">
.custom-sheet-product__close-button {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 32px;
    height: 32px;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 50%;
    cursor: pointer;
    z-index: 1000;
    box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.12);

    background-image: url('../../static/close-button.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 11px 11px;
}
.grocery-product-popup {
    height: calc(100vh - 50px - constant(safe-area-inset-top));
    height: calc(100vh - 50px - env(safe-area-inset-top));
    box-shadow: 0px 0px 12px 0px #0000001F;

    .hr-line {
        margin: 16px 0;
    }
}
.grocery-product-popup__container {
    height: calc(100vh - 120px - constant(safe-area-inset-top) - constant(safe-area-inset-bottom));
    height: calc(100vh - 120px - env(safe-area-inset-top) - env(safe-area-inset-bottom));
}
.grocery-product-popup__content {
    height: calc(100vh - 370px - constant(safe-area-inset-top) - constant(safe-area-inset-bottom));
    height: calc(100vh - 370px - env(safe-area-inset-top) - env(safe-area-inset-bottom));
    overflow: hidden;

    & > div {
        height: 100%;
        padding: 15px 0;
        overflow-y: auto;
    }
}
.grocery-product-popup__picture__wrapper {
    margin: -20px -20px 0 -20px;
    overflow: hidden;
    background-color: white;
}
.grocery-product-popup__picture {
    position: relative;
    width: 100%;
    height: 250px;
    overflow: hidden;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url('../../static/empty-img.svg');
}
.grocery-product-popup__discount {
    position: absolute;
    top: 7px; 
    right: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #333333;
    border: 2px solid #FFFFFF;
    border-radius: 32px;
}
.grocery-product-popup__title {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    color: #323232;
}
.grocery-product-popup__price {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 3px 0 0 20px;
    
    font-size: 18px;
    font-weight: 600;
    line-height: 19px;
    color: #323232;
    
    > span {
        white-space: nowrap;
    }
    > span.grocery-product-popup__weight {
        white-space: nowrap;
        padding-top: 10px;

        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        color: #323232;
        opacity: .5;
    }
}
.grocery-product-popup__old-price {
    position: relative;
    
    font-size: 14px;
    font-weight: 300;
    line-height: 16px;
    text-decoration: line-through;

    color: #929292;

    > span {
        white-space: nowrap;
    }
}

.grocery-product-popup__description {
    margin-top: 10px;
    
    font-weight: normal;
    font-size: 15px;
    word-break: break-word;
    line-height: 140%;
    color: #232321;

    &__item {
        display: block;
        margin-top: 10px;

        font-size: 13px;
        font-weight: 400;
        line-height: 15px;
        color: #32323280;

        & > b {
            color: #323232;
            font-weight: 400;
        }
    }
    
    &__numbers {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        margin-top: -10px;
    
        &__item {
            padding: 8px 16px;
            border-radius: 10px;
            border: 1px solid #E5E6E9;
            margin-top: 10px;

            font-size: 12px;
            font-weight: 400;
            line-height: 14px;
            text-align: center;
            color: #32323280;

            > span {
                font-size: 14px;
                font-weight: 400;
                line-height: 16px;
                color: #323232;
            }
        }
    }
}

.grocery-product-popup__add-to-cart {
    display: flex;
    height: calc(constant(safe-area-inset-bottom) + 70px);
    height: calc(env(safe-area-inset-bottom) + 70px);
    padding: 0 20px;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    align-items: center;
    justify-content: space-between;
    background: #FFFFFF;
    box-shadow: 0px -11px 41px 0px #0000000F;
    margin: 0 -20px -20px -20px;
    z-index: 100;

    .stepper {
        width: 100%;
        height: 43px;
        background: none;

        &-wrapper {
            height: 43px;
        }

        .stepper-button-minus,
        .stepper-button-plus {
            width: 43px;
            height: 43px;
            background: #F0F2F3;
            border-radius: 10px;
            
        }
    }
}
.grocery-product-popup__add-to-cart__stepper {
    background: #E7E7E7;
    border-radius: 6px;
}
.grocery-product-popup__add-to-cart__button {
    width: calc(100% - 140px);
    max-width: 200px;
    justify-content: space-between;
}
</style>
